



































































































































































































































































import { defineComponent, computed, ref } from '@nuxtjs/composition-api';
import useRootInstance from '@/shared/useRootInstance';
import useModal from '@/shared/useModal';
import { usePlp } from '@vf/composables/src/useStaticLayout';
import { useLoyalty, useI18n, useShippingFilter } from '@vf/composables';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import { useUserStore } from '@vf/composables/src/store/user';
import { useHeaderAndFooterStore } from '@vf/composables/src/store/headerAndFooter';
import {
  handlePreviewModeClientSide,
  transformSeoMetadata,
  useUserData,
} from '../../components/cms/cmsUtils';
import { isClient } from '@vf/shared/src/utils/helpers';

import CategoryTitle from '@/components/smart/plp/CategoryTitle.vue';
import CategorySort from '@/components/smart/plp/CategorySort.vue';
import CategoryFilters from '@/components/smart/plp/CategoryFilters.vue';
import CategoryFiltersDisplayToggle from '@/components/smart/plp/CategoryFiltersDisplayToggle.vue';
import CategoryProducts from '@/components/smart/plp/CategoryProducts.vue';
import ViewMoreProductsButtons from '@/components/smart/shared/ViewMoreProductsButtons.vue';
import SeoRelatedWidgetTextOnly from '@/components/smart/shared/SeoRelatedWidgetTextOnly.vue';
import Breadcrumbs from '@/components/smart/common/Breadcrumbs.vue';
import DiscountNotification from '@/components/smart/shared/DiscountNotification.vue';
import Placement from '@/components/smart/grid/Placement.vue';
import BackToTopButton from '@/components/smart/common/BackToTopButton.vue';
import MonetateExperience from '@/components/smart/monetate/MonetateExperience.vue';
import CMVendorPlaceholder from '@/components/smart/shared/CMVendorPlaceholder.vue';
import FloatingChatButton from '@/components/smart/shared/FloatingChatButton.vue';

export default defineComponent({
  name: 'PLPstatic',
  components: {
    Breadcrumbs,
    DiscountNotification,
    CategoryTitle,
    CategorySort,
    CategoryFilters,
    CategoryFiltersDisplayToggle,
    CategoryProducts,
    CmsDynamicSlot: () => import('@/components/cms/CmsDynamicSlot.vue'),
    ViewMoreProductsButtons,
    SeoRelatedWidgetTextOnly,
    BackToTopButton,
    MonetateExperience,
    CMVendorPlaceholder,
    Placement,
    FloatingChatButton,
    VfShippingFilter: () =>
      import('@/components/static/plp/ShippingFilter.vue'),
  },
  layout: 'defaultStatic',
  transition: 'fade',
  props: {},
  setup() {
    const { root } = useRootInstance();
    const { openModal } = useModal();

    const {
      areMonetateExperiencesSetInCmsForTemplatizedPages,
      isVansPlpRedesignEnabled, // TODO: GLOBAL15-63799 update when redesign work is done
      isPointsToCurrencyEnabled,
    } = useFeatureFlagsStore();
    const headerAndFooterStore = useHeaderAndFooterStore();
    const userStore = useUserStore(root);
    const { currencySymbol } = useI18n(root);
    const { rewards } = useLoyalty(root);
    const { isEnableShippingFilter } = useShippingFilter(root);

    const filtersColumnWidth = isVansPlpRedesignEnabled
      ? {
          large: 2,
          medium: 2,
          small: 12,
          mediumMargins: { top: 'md-no-margin-t' },
          largeMargins: { top: 'lg-no-margin-t' },
        }
      : {
          large: 3,
          medium: 3,
          small: 12,
        };

    const {
      meta,
      showFiltersBar,
      monetateExperiencesPlp,
      dynamicSlots,
      categoryProductsModalsProps,
      seoWidgetRecommendationsEnabled,
      isBlankCategoryResponse,
      contextKey,
    } = usePlp(root, {
      handlePreviewModeClientSide,
      openModal,
      useUserData,
    });

    // TODO: GLOBAL15-63799 update when redesign work is done
    const productColumnWidth = computed(() =>
      isVansPlpRedesignEnabled
        ? {
            large: showFiltersBar.value ? 10 : 12,
            medium: showFiltersBar.value ? 10 : 12,
            small: 12,
            mediumMargins: { top: 'md-no-margin-t' },
            largeMargins: { top: 'lg-no-margin-t' },
          }
        : {
            large: showFiltersBar.value ? 9 : 12,
            medium: showFiltersBar.value ? 9 : 12,
            small: 12,
          }
    );

    const currency = currencySymbol.value;
    const isLoyaltyToastHidden = ref(
      isClient
        ? !!sessionStorage.getItem('isLoyaltyRewardsAvailableBannerHidden')
        : true
    );
    const totalRewards = computed(() => {
      const reward = [...rewards.value.applicableLoyaltyVouchers]
        .reverse()
        .find((voucher) => voucher.available)?.amountInCurrency;
      return reward ? root.$formatPrice(reward, currency) : 0;
    });

    const showLoyaltyToast = computed(
      () =>
        isPointsToCurrencyEnabled &&
        userStore.loyaltyEnrolled &&
        !headerAndFooterStore.showHeader &&
        !isLoyaltyToastHidden.value &&
        totalRewards.value
    );

    return {
      meta,
      showFiltersBar,
      monetateExperiencesPlp,
      dynamicSlots,
      categoryProductsModalsProps,
      seoWidgetRecommendationsEnabled,
      isBlankCategoryResponse,
      isEnableShippingFilter,
      isVansPlpRedesignEnabled,
      contextKey,
      filtersColumnWidth,
      productColumnWidth,
      areMonetateExperiencesSetInCmsForTemplatizedPages,
      showLoyaltyToast,
      totalRewards,
      isLoyaltyToastHidden,
    };
  },
  head() {
    return transformSeoMetadata(this);
  },
});
